import { AnyAction } from "redux";
import { getCurrentMeetMetadata } from "../reduxStoreManager";
import { updateMeet } from "./meetService";

const ERROR_DELAY_MS = 5000;

interface MeetUpdateData {
  actions: AnyAction[];
  meetId: string;
  password: string;
}

export class RemoteStoreUpdateService {
  pendingUpdates: Map<string, MeetUpdateData>;
  isBusy: boolean;

  constructor() {
    this.pendingUpdates = new Map();
    this.isBusy = false;
  }

  onAction(action: AnyAction) {
    // Don't sync overwrite actions, they're internal
    if (action.type === "OVERWRITE_STORE") {
      return;
    }

    const currentMeetMetadata = getCurrentMeetMetadata();

    // Should never be true - indicates a bug or regression. This check helps the compiler narrow the type too
    if (!currentMeetMetadata) {
      throw new Error("Current meet metadata has not been set, impossible for the action to be synchronised");
    }

    let pendingUpdatesForMeet = this.pendingUpdates.get(currentMeetMetadata.id);
    if (!pendingUpdatesForMeet) {
      pendingUpdatesForMeet = {
        actions: [],
        meetId: currentMeetMetadata.id,
        password: currentMeetMetadata.password,
      };

      this.pendingUpdates.set(currentMeetMetadata.id, pendingUpdatesForMeet);
    }

    pendingUpdatesForMeet.actions = [...pendingUpdatesForMeet.actions, action];

    // Kick off the sync process if it isn't already running
    if (!this.isBusy) {
      this.isBusy = true;
      // Use setTimeout here so that we kick this off on the next cycle of the event-loop.
      // This helps ensure that if a flurry of actions are about to be kicked off, they'll be queue'd up before we send them off.
      // Otherwise we end up with 2 requests when we could've just sent 1
      setTimeout(() => this.startSynchronisingActions(), 0);
    }
  }

  async startSynchronisingActions() {
    try {
      while (this.pendingUpdates.size > 0) {
        for (const currentMeetUpdateInfo of this.pendingUpdates.values()) {
          try {
            const actions = [...currentMeetUpdateInfo.actions];
            const response = await updateMeet(currentMeetUpdateInfo.meetId, actions, currentMeetUpdateInfo.password);
            // If we get a meet not found response, either the password has changed, or the meet has been deleted - either way its impossible to recover.
            // - We should really escalate this somehow and let the user know they their work will never be saved. For now, just move on
            if (response.success || response.error?.code === "MEET_NOT_FOUND") {
              // Clone & slice the pending actions array based on the size of the actions we just sent - its possible that more actions were enqueued while we were sending the update
              currentMeetUpdateInfo.actions = currentMeetUpdateInfo.actions.slice(actions.length);
              // if there are no more pending actions for this meet, its safe to remove it from the collection
              if (currentMeetUpdateInfo.actions.length === 0) {
                this.pendingUpdates.delete(currentMeetUpdateInfo.meetId);
              }
            } else {
              console.log(
                `Some sort of error occurred when trying to update the meet. Error has code "${response.error?.code}" with message "${response.error?.message}"`
              );
              await sleep(ERROR_DELAY_MS);
            }
          } catch (error) {
            console.log(
              `Some sort of error occurred when trying to update the meet. Error: ${error.message}. Stack: ${error.stack}. Raw error: ${error}`
            );
            await sleep(ERROR_DELAY_MS);
          }
        }
      }
    } finally {
      this.isBusy = false;
    }
  }
}

function sleep(timeout: number): Promise<unknown> {
  const promise = new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });

  return promise;
}
