import { faDownload, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { ApiMeetWithDate } from "../../containers/HomeContainer";

interface MeetsListProps {
  meets: ApiMeetWithDate[] | null;
  onMeetDeleteClick: (id: string) => void;
  onMeetClick: (id: string) => void;
  onMeetSaveClick: (id: string) => void;
}

export class MeetsList extends React.Component<MeetsListProps> {
  render() {
    return <div>{this.renderBody()}</div>;
  }

  renderBody() {
    if (this.props.meets === null) {
      return (
        <div className="d-flex justify-content-center">
          <FontAwesomeIcon style={{ margin: "5px", fontSize: 100 }} icon={faSpinner} spin />
        </div>
      );
    }

    return (
      <Table
        className="table-striped table-hover"
        style={{ border: "solid", borderColor: "#dee2e6", borderWidth: "1px" }}
      >
        <tbody>
          {this.props.meets.map((meet, index) => (
            <MeetRow
              onMeetClick={() => this.props.onMeetClick(meet.id)}
              onMeetSaveClick={() => this.props.onMeetSaveClick(meet.id)}
              onMeetDeleteClick={() => this.props.onMeetDeleteClick(meet.id)}
              key={index}
              meetName={meet.name}
              meetDate={meet.date}
            />
          ))}
        </tbody>
      </Table>
    );
  }
}

interface MeetRowProps {
  meetName: string;
  meetDate: Date;
  onMeetDeleteClick: () => void;
  onMeetClick: () => void;
  onMeetSaveClick: () => void;
}

interface MeetRowState {
  hovering: boolean;
}

class MeetRow extends React.Component<MeetRowProps, MeetRowState> {
  constructor(props: MeetRowProps) {
    super(props);
    this.state = {
      hovering: false,
    };
  }

  render() {
    return (
      <tr
        onMouseEnter={() => this.setState({ hovering: true })}
        onMouseLeave={() => this.setState({ hovering: false })}
        style={{ cursor: "pointer" }}
        onClick={() => this.props.onMeetClick()}
      >
        <td style={{ width: "100%" }}>
          <div className="d-flex align-items-center" style={{ minHeight: "31px" }}>
            <span className="mr-auto">{this.props.meetName}</span>
            {this.state.hovering && (
              <>
                <OverlayTrigger placement="left" overlay={<Tooltip id="download-meet-tooltip">Download</Tooltip>}>
                  <Button
                    onClick={(e: React.BaseSyntheticEvent) => {
                      e.stopPropagation();
                      this.props.onMeetSaveClick();
                    }}
                    variant="dark"
                    style={{ marginLeft: "5px" }}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger placement="right" overlay={<Tooltip id="delete-meet-tooltip">Delete</Tooltip>}>
                  <Button
                    onClick={(e: React.BaseSyntheticEvent) => {
                      e.stopPropagation();
                      this.props.onMeetDeleteClick();
                    }}
                    variant="danger"
                    style={{ marginLeft: "5px" }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </OverlayTrigger>
              </>
            )}
          </div>
        </td>

        <td>
          <div className="d-flex align-items-center" style={{ minHeight: "31px" }}>
            {this.props.meetDate.toLocaleDateString()}
          </div>
        </td>
      </tr>
    );
  }
}
