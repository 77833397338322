// vim: set ts=2 sts=2 sw=2 et:
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

// The confirmation modal that pops up when the "New Meet" button is clicked.

import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { getString } from "../../logic/strings";

import { Language } from "../../types/dataTypes";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface Props {
  title: string;
  submitButtonText: string;
  language: Language;
  close: () => void;
  sumbmit: (meetPassword: string) => Promise<boolean>;
}

interface State {
  password: string;
  submitting: boolean;
  isIncorrectPassword: boolean;
}

class PasswordPromptModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      password: "",
      submitting: false,
      isIncorrectPassword: false,
    };
  }

  render() {
    return (
      <Modal show={true} onHide={() => this.props.close()} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={this.state.password}
                isInvalid={this.state.isIncorrectPassword}
                onChange={(x: React.BaseSyntheticEvent) => {
                  this.setState({
                    password: x.currentTarget.value,
                  });
                }}
              />
              <Form.Control.Feedback type="invalid">Invalid Password</Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => this.props.close()} variant="light">
            {getString("common.button-close", this.props.language)}
          </Button>
          <Button
            disabled={!this.canSubmit()}
            onClick={async () => {
              this.setState({
                submitting: true,
              });

              const isCorrectPassword = await this.props.sumbmit(this.state.password);
              this.setState({
                isIncorrectPassword: !isCorrectPassword,
                submitting: false,
              });
            }}
            variant="primary"
          >
            {this.state.submitting ? (
              <FontAwesomeIcon style={{ margin: "5px" }} icon={faSpinner} spin />
            ) : (
              this.props.submitButtonText
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  canSubmit() {
    return this.state.password.length > 0 && !this.state.submitting;
  }
}

export default PasswordPromptModal;
