// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import thunk from "redux-thunk";
import { getDefaultLanguage } from "./logic/strings";

import rootReducer from "./reducers/rootReducer";
import { ReduxStoreActionEmitter } from "./ReduxStoreActionEmitter";
import { RemoteStoreUpdateService } from "./services/remoteStoreUpdateService";

const remoteStoreUpdateService = new RemoteStoreUpdateService();
const storeActionListener = new ReduxStoreActionEmitter();

storeActionListener.subscribe((action) => remoteStoreUpdateService.onAction(action));

interface CurrentMeetMetadata {
  id: string;
  password: string;
}

// This is fairly hacky, should probably be moved onto the store somehow?
let currentMeetMetadata: CurrentMeetMetadata | null;
export function getCurrentMeetMetadata() {
  return currentMeetMetadata;
}

export function setCurrentMeetMetadata(value: CurrentMeetMetadata) {
  currentMeetMetadata = value;
}

export const store = createStore(
  rootReducer,
  { language: getDefaultLanguage() },
  composeWithDevTools(applyMiddleware(thunk, storeActionListener.createReduxMiddleware()))
);
