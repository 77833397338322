import { GlobalState } from "../types/stateTypes";

export const MeetPasswordHeader = "x-meet-password";
export const RecordsPasswordHeader = "x-records-password";

export interface ApiResponse<TResponseType> {
  success: boolean;
  data: TResponseType | null;
  error?: {
    code: ResponseErrorCode;
    message: string;
  } | null;
}

export interface ApiMeet {
  id: string;
  name: string;
  date: string;
}

export interface MeetsListResponse {
  meets: ApiMeet[];
}

export interface LoadMeetResponse {
  state: GlobalState;
}

export interface CreateMeetRequest {
  state: GlobalState;
}

export type CreateMeetResponse = LoadMeetResponse;

export interface UpdateMeetActionRequest {
  actions: any[];
}

export interface PublishRecordsRequest {
  csv: string;
  html: string;
}

export interface LoadPublishedRecordsResponse {
  csv: string;
}

export enum ResponseErrorCode {
  MeetNotFound = "MEET_NOT_FOUND",
  IncorrectPassword = "INCORRECT_PASSWORD",
}
