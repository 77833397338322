// vim: set ts=2 sts=2 sw=2 et:
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

// The confirmation modal that pops up when the "New Meet" button is clicked.

import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";

import { getString } from "../../logic/strings";

import { Language } from "../../types/dataTypes";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface Props {
  language: Language;
  close: () => void;
  sumbmit: (meetName: string, meetDate: Date, meetPassword: string) => void;
}

interface State {
  meetName: string;
  meetDate: Date;
  meetPassword: string;
  submitting: boolean;
}

class GpCreateMeetModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      meetName: "",
      meetDate: new Date(),
      meetPassword: "",
      submitting: false,
    };
  }

  render() {
    return (
      <Modal show={true} onHide={this.props.close} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Create a new Meet?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formMeetName">
              <Form.Label>Meet Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={this.state.meetName}
                onChange={(x: React.BaseSyntheticEvent) => {
                  this.setState({
                    meetName: x.currentTarget.value,
                  });
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={this.state.meetPassword}
                onChange={(x: React.BaseSyntheticEvent) => {
                  this.setState({
                    meetPassword: x.currentTarget.value,
                  });
                }}
              />
              <Form.Text className="text-muted">This will be required to open and run the meet.</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formMeetDate">
              <Form.Label>Meet Date</Form.Label>
              <div>
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={this.state.meetDate}
                  onChange={(date) => {
                    if (date instanceof Date) {
                      this.setState({
                        meetDate: date,
                      });
                    }
                  }}
                  locale={this.props.language}
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => this.props.close()} variant="light">
            {getString("common.button-close", this.props.language)}
          </Button>
          <Button
            disabled={!this.canSubmit()}
            onClick={() => {
              this.setState({
                submitting: true,
              });

              this.props.sumbmit(this.state.meetName, this.state.meetDate, this.state.meetPassword);
            }}
            variant="primary"
          >
            {this.state.submitting ? <FontAwesomeIcon style={{ margin: "5px" }} icon={faSpinner} spin /> : "Create"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  canSubmit() {
    return this.state.meetName.length > 0 && this.state.meetPassword.length > 0 && !this.state.submitting;
  }
}

export default GpCreateMeetModal;
