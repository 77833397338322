// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

import React from "react";
import { connect } from "react-redux";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { LinkContainer } from "react-router-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { saveAs } from "file-saver";
import Logo from "../components/lifting/gpc-nz-horizontal.png";
import { FormattedMessage } from "react-intl";
import { overwriteStore } from "../actions/globalActions";
import ErrorModal from "../components/modals/ErrorModal";
import { getString } from "../logic/strings";
import { stateVersion, releaseVersion, releaseDate } from "../versions";
import styles from "../components/common/ContentArea.module.scss";
import { GlobalState, MeetState } from "../types/stateTypes";
import { createStore, Dispatch } from "redux";
import { MeetsList } from "../components/home/MeetsList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import NewNewMeetModal from "../components/home/GpCreateMeetModal";
import { createNewMeet, deleteMeet, fetchMeets, loadMeet, loadMeetRecordsCsv } from "../services/meetService";
import rootReducer from "../reducers/rootReducer";
import { updateMeet } from "../actions/meetSetupActions";
import { localDateToIso8601 } from "../logic/date";
import PasswordPromptModal from "../components/modals/MeetPasswordPromptModal";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { setCurrentMeetMetadata } from "../reduxStoreManager";
import { gpcDefaults } from "../components/meet-setup/AutoFillRules";
import { ApiResponse, LoadPublishedRecordsResponse } from "../dtos/dtos";
import { loadRecords } from "../logic/import/records-csv";
import { Language, LiftingRecord } from "../types/dataTypes";
import { importRecords } from "../actions/recordActions";

export interface ApiMeetWithDate {
  id: string;
  name: string;
  date: Date;
}

// Temporary CSS, just for prototyping.
const centerConsole = {
  maxWidth: 700,
  marginRight: "auto",
  marginLeft: "auto",
};

interface StateProps {
  redux: GlobalState;
}

interface DispatchProps {
  overwriteStore: (store: GlobalState) => void;
}

interface InternalState {
  showNewMeetModal: boolean;
  promptOpenMeetId: string | null;
  promptDeleteMeetId: string | null;
  promptSaveMeetId: string | null;
  // Controls the ErrorModal popup. Shown when error !== "".
  error: string;
  errorTitle: string | null;
  upcomingMeets: ApiMeetWithDate[] | null;
  historicMeets: ApiMeetWithDate[] | null;
  redirectTo: string | null;
}

type Props = StateProps & DispatchProps & RouteComponentProps<{}>;

// Gets links and buttons to have the same vertical spacing.
const buttonMargin = { marginBottom: "8px" };

class HomeContainer extends React.Component<Props, InternalState> {
  constructor(props: Props) {
    super(props);
    this.handleLoadClick = this.handleLoadClick.bind(this);
    this.handleNewClick = this.handleNewClick.bind(this);
    this.closeConfirmModal = this.closeConfirmModal.bind(this);
    this.closeErrorModal = this.closeErrorModal.bind(this);
    this.handleLoadFileInput = this.handleLoadFileInput.bind(this);

    this.state = {
      showNewMeetModal: false,
      error: "",
      errorTitle: null,
      upcomingMeets: null,
      historicMeets: null,
      promptOpenMeetId: null,
      promptDeleteMeetId: null,
      promptSaveMeetId: null,
      redirectTo: null,
    };
  }

  async componentDidMount() {
    await this.loadMeetList();
  }

  async loadMeetList() {
    try {
      const responseObject = await fetchMeets();
      const meets = responseObject.data?.meets
        .map((meet) => ({
          id: meet.id,
          name: meet.name,
          date: new Date(meet.date),
        }))
        .sort((a, b) => a.date.getTime() - b.date.getTime());

      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      if (meets) {
        this.setState({
          upcomingMeets: meets.filter((meet) => meet.date >= today),
          historicMeets: meets.filter((meet) => meet.date < today),
        });
      }
    } catch {
      this.showGenericErrorMessage();
    }
  }

  // The file input is hidden, and we want to use a button to activate it.
  // This event handler is just a proxy to call the *real* event handler.
  handleLoadClick = () => {
    const loadhelper = document.getElementById("loadhelper");
    if (loadhelper !== null) {
      loadhelper.click();
    }
  };

  // When we click the new meet button
  // Open the popover modal to confirm the user is willing to delete any current progress
  handleNewClick = () => {
    this.setState({ showNewMeetModal: true });
  };

  // Close the new meet confirmation modal
  closeConfirmModal = () => {
    this.setState({ showNewMeetModal: false });
  };

  closeErrorModal = () => {
    this.setState({ error: "" });
  };

  handleMeetClick(id: string) {
    this.setState({
      promptOpenMeetId: id,
    });
  }

  handleMeetDeleteClick(id: string) {
    this.setState({
      promptDeleteMeetId: id,
    });
  }

  handleMeetSaveClick(id: string) {
    this.setState({
      promptSaveMeetId: id,
    });
  }

  // Called when a file is selected.
  handleLoadFileInput = () => {
    // Load the element and make sure it's an HTMLInputElement.
    const loadHelper = document.getElementById("loadhelper");
    if (loadHelper === null || !(loadHelper instanceof HTMLInputElement) || loadHelper.files === null) {
      return;
    }

    const selectedFile = loadHelper.files[0];
    const language = this.props.redux.language;
    const rememberThis = this;

    const reader = new FileReader();
    reader.onload = function (event: any) {
      let errored = false;
      try {
        const obj = JSON.parse(event.target.result);

        // Basic error checking, make sure it's the right format.
        if (
          obj.language === undefined ||
          obj.meet === undefined ||
          obj.registration === undefined ||
          obj.lifting === undefined
        ) {
          errored = true;
        } else {
          rememberThis.props.overwriteStore(obj);
        }
      } catch (err) {
        errored = true;
      }

      if (errored) {
        const error = getString("error.invalid-openlifter", language);
        rememberThis.setState({ error: error });
      }
    };
    reader.readAsText(selectedFile);

    // this will reset the input field so the same file can be selected again. Without this picking the same file for import silently does nothing
    loadHelper.value = "";
  };

  async runWithErrorHandling(func: () => Promise<any>) {
    try {
      await func();
    } catch {
      this.setState({
        error: "ye",
      });
    }
  }

  // Render any modals if we need to
  renderModals() {
    if (this.state.showNewMeetModal) {
      return (
        <NewNewMeetModal
          language={this.props.redux.language}
          sumbmit={(meetName: string, meetDate: Date, meetPassword: string) =>
            this.createNewMeet(meetName, meetDate, meetPassword)
          }
          close={() => this.closeConfirmModal()}
        />
      );
    }

    if (this.state.promptOpenMeetId) {
      const meetId = this.state.promptOpenMeetId;
      return (
        <PasswordPromptModal
          title="Open Meet"
          submitButtonText="Open"
          language={this.props.redux.language}
          close={() =>
            this.setState({
              promptOpenMeetId: null,
            })
          }
          sumbmit={async (password) => {
            try {
              const result = await loadMeet(meetId, password);
              if (result.success && result.data !== null) {
                const state = result.data?.state;
                this.props.overwriteStore(state);
                setCurrentMeetMetadata({
                  id: meetId,
                  password,
                });
                this.setState({
                  promptOpenMeetId: null,
                });
                this.props.history.push("/meet-setup");
                return true;
              } else {
                return false;
              }
            } catch {
              this.showGenericErrorMessage();
              return true;
            }
          }}
        />
      );
    }

    if (this.state.promptDeleteMeetId) {
      const deleteMeetId = this.state.promptDeleteMeetId;
      return (
        <PasswordPromptModal
          title="Delete Meet"
          submitButtonText="Delete"
          language={this.props.redux.language}
          close={() =>
            this.setState({
              promptDeleteMeetId: null,
            })
          }
          sumbmit={async (password) => {
            try {
              const result = await deleteMeet(deleteMeetId, password);
              if (result.success) {
                this.setState({
                  promptDeleteMeetId: null,
                });
                await this.loadMeetList();
                return true;
              } else {
                return false;
              }
            } catch {
              this.showGenericErrorMessage();
              return true;
            }
          }}
        />
      );
    }
    if (this.state.promptSaveMeetId) {
      const saveMeetId = this.state.promptSaveMeetId;
      return (
        <PasswordPromptModal
          title="Download Meet"
          submitButtonText="Download"
          language={this.props.redux.language}
          close={() =>
            this.setState({
              promptSaveMeetId: null,
            })
          }
          sumbmit={async (password) => {
            try {
              const result = await loadMeet(saveMeetId, password);
              if (result.success && result.data) {
                let meetname = result.data.state.meet.name;
                if (meetname === "") {
                  meetname = getString("common.unnamed-filename", this.props.redux.language);
                }

                meetname = meetname.replace(/ /g, "-");

                const state = JSON.stringify(result.data.state);
                const blob = new Blob([state], { type: "application/json;charset=utf-8" });
                saveAs(blob, meetname + ".openlifter");

                this.setState({
                  promptSaveMeetId: null,
                });
                await this.loadMeetList();
                return true;
              } else {
                return false;
              }
            } catch {
              this.showGenericErrorMessage();
              return true;
            }
          }}
        />
      );
    }
  }

  render() {
    const wrongVersion: boolean = this.props.redux.versions.stateVersion !== stateVersion;
    const dataReleaseVersion = this.props.redux.versions.releaseVersion;

    const language = this.props.redux.language;

    let warning = null;
    if (wrongVersion === true) {
      warning = (
        <h3>
          <p>
            <b>{getString("common.danger-allcaps", language)}</b>
          </p>
          <p>
            <FormattedMessage
              id="home.wrong-version-warning"
              defaultMessage="The loaded meet was made in OpenLifter {oldVersion}. That format is incompatible with OpenLifter {thisVersion}."
              values={{ oldVersion: dataReleaseVersion, thisVersion: releaseVersion }}
            />
          </p>
        </h3>
      );
    }

    const modalDialog = this.renderModals();
    return (
      <Card style={centerConsole} className={styles.contentArea}>
        <ErrorModal
          error={this.state.error}
          title={this.state.errorTitle || getString("home.error-load-popup-title", language)}
          show={this.state.error !== ""}
          close={this.closeErrorModal}
        />

        {modalDialog}

        <Card.Header style={{ padding: 0 }}>
          <img alt="GPC-NZ" src={Logo} style={{ width: "100%", backgroundColor: "black" }} />
        </Card.Header>

        <Card.Body>
          <Container>
            <Row>{warning}</Row>
            <Row style={buttonMargin}>
              <Col md={8}>
                {wrongVersion && (
                  <a href={"https://www.openlifter.com/releases/" + dataReleaseVersion}>
                    <Button variant="success" block>
                      <FormattedMessage
                        id="home.button-switch-version"
                        defaultMessage="Switch to OpenLifter {otherVersion}"
                        values={{ otherVersion: dataReleaseVersion }}
                      />
                    </Button>
                  </a>
                )}
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="d-flex mb-2">
                  <h1 className="mr-auto">Current &amp; Upcoming Meets</h1>
                  <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip id="create-new-meet-tooltip">Create a new meet</Tooltip>}
                  >
                    <Button onClick={this.handleNewClick}>
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </OverlayTrigger>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <MeetsList
                  meets={this.state.upcomingMeets}
                  onMeetClick={(id) => this.handleMeetClick(id)}
                  onMeetDeleteClick={(id) => this.handleMeetDeleteClick(id)}
                  onMeetSaveClick={(id) => this.handleMeetSaveClick(id)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-flex mb-2">
                  <h1 className="mr-auto">Past Meets</h1>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <MeetsList
                  meets={this.state.historicMeets}
                  onMeetClick={(id) => this.handleMeetClick(id)}
                  onMeetDeleteClick={(id) => this.handleMeetDeleteClick(id)}
                  onMeetSaveClick={(id) => this.handleMeetSaveClick(id)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <a
                  href="https://gitlab.com/openpowerlifting/openlifter/issues/new"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Button variant="outline-secondary" block style={buttonMargin}>
                    <FormattedMessage id="home.button-report-issue" defaultMessage="Report an Issue" />
                  </Button>
                </a>
              </Col>
              <Col>
                <a href="https://www.openlifter.com/support" rel="noopener noreferrer" target="_blank">
                  <Button variant="outline-secondary" block style={buttonMargin}>
                    <FormattedMessage id="home.button-support" defaultMessage="Official Support" />
                  </Button>
                </a>
              </Col>
              <Col>
                <a href="https://gitlab.com/openpowerlifting/openlifter" rel="noopener noreferrer" target="_blank">
                  <Button variant="outline-secondary" block style={buttonMargin}>
                    <FormattedMessage id="home.button-source" defaultMessage="Full Source Code" />
                  </Button>
                </a>
              </Col>
              <Col>
                <LinkContainer to="/about">
                  <Button variant="outline-secondary" block style={buttonMargin}>
                    <FormattedMessage id="home.button-credits" defaultMessage="Credits and License" />
                  </Button>
                </LinkContainer>
              </Col>
            </Row>
          </Container>
        </Card.Body>

        <Card.Footer>
          <h4 style={{ textAlign: "center" }}>
            <FormattedMessage
              id="home.version-date"
              defaultMessage="Version {releaseVersion}, {releaseDate}."
              values={{
                releaseVersion: releaseVersion,
                releaseDate: releaseDate,
              }}
            />
          </h4>
        </Card.Footer>

        <input
          id="loadhelper"
          type="file"
          accept=".openlifter,.openlifter.txt"
          style={{ display: "none" }}
          onChange={this.handleLoadFileInput}
        />
      </Card>
    );
  }

  async createNewMeet(meetName: string, meetDate: Date, meetPassword: string) {
    try {
      const store = createStore(rootReducer);
      store.dispatch(
        updateMeet({
          name: meetName,
          date: localDateToIso8601(meetDate),
        })
      );

      store.dispatch(updateMeet(gpcDefaults));
      const state = store.getState();
      const records = await this.loadRecords(state.meet, state.language);
      if (records !== null) {
        store.dispatch(importRecords(records));
      }

      await createNewMeet(uuidv4().toString(), meetPassword, store.getState());
      await this.loadMeetList();
    } catch {
      this.showGenericErrorMessage();
    } finally {
      this.setState({
        showNewMeetModal: false,
      });
    }
  }

  async loadRecords(meet: MeetState, language: Language): Promise<LiftingRecord[] | null> {
    let recordsResponse: ApiResponse<LoadPublishedRecordsResponse> | null = null;
    try {
      recordsResponse = await loadMeetRecordsCsv();
      if (recordsResponse.success && recordsResponse.data) {
        const records = loadRecords(recordsResponse.data.csv, meet, language);
        if (typeof records === "string") {
          this.showGenericErrorMessage();
        } else {
          return records;
        }
      } else if (recordsResponse.error) {
        return null;
      }
    } catch (error) {
      return null;
    }

    return null;
  }

  // Not a great experience - should aim to make this more specific
  showGenericErrorMessage() {
    this.setState({
      errorTitle: "Error",
      error: "Something went wrong. Please try again",
    });
  }
}

// Because we want to save the state, separate it out specifically
// into a "redux" prop. Otherwise it gets contaminated by other props.
const mapStateToProps = (state: GlobalState): StateProps => ({
  redux: {
    ...state,
  },
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    overwriteStore: (store) => dispatch(overwriteStore(store)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeContainer));
