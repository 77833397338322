import {
  ApiResponse,
  CreateMeetRequest,
  CreateMeetResponse,
  LoadMeetResponse,
  LoadPublishedRecordsResponse,
  MeetPasswordHeader,
  MeetsListResponse,
  PublishRecordsRequest,
  RecordsPasswordHeader,
  UpdateMeetActionRequest,
} from "../dtos/dtos";
import { GlobalState } from "../types/stateTypes";

export async function fetchMeets() {
  return await fetchAndParse<MeetsListResponse>("/api/meets");
}

export async function createNewMeet(id: string, password: string, state: GlobalState) {
  const requestDto: CreateMeetRequest = {
    state: state,
  };

  return await fetchAndParse<CreateMeetResponse>(`/api/meets/${id}`, {
    body: JSON.stringify(requestDto),
    method: "POST",
    headers: addJsonContentHeader(createPasswordHeader(password)),
  });
}

export async function loadMeet(id: string, password: string) {
  return await fetchAndParse<LoadMeetResponse>(`/api/meets/${id}`, {
    headers: createPasswordHeader(password),
  });
}

export async function deleteMeet(id: string, password: string) {
  return await fetchAndParse<void>(`/api/meets/${id}`, {
    method: "DELETE",
    headers: createPasswordHeader(password),
  });
}

export async function updateMeet(id: string, actions: any[], password: string) {
  const requestBody: UpdateMeetActionRequest = {
    actions,
  };

  return await fetchAndParse<void>(`/api/meets/${id}/actions`, {
    method: "POST",
    headers: addJsonContentHeader(createPasswordHeader(password)),
    body: JSON.stringify(requestBody),
  });
}

async function fetchAndParse<TResponseType>(url: string, init?: RequestInit) {
  const response = await fetch(url, init);
  const result = (await response.json()) as ApiResponse<TResponseType>;
  return result;
}

export async function publishMeetsRecords(recordPublishingPassword: string, csv: string, html: string) {
  const headers = addJsonContentHeader({});
  headers[RecordsPasswordHeader] = recordPublishingPassword;

  const requestBody: PublishRecordsRequest = {
    csv,
    html,
  };

  return await fetchAndParse(`/api/records/publish`, {
    method: "POST",
    headers,
    body: JSON.stringify(requestBody),
  });
}

export async function loadMeetRecordsCsv() {
  return await fetchAndParse<LoadPublishedRecordsResponse>(`/api/records/`);
}

function createPasswordHeader(password: string) {
  const headers: Record<string, string> = {};
  headers[MeetPasswordHeader] = password;
  return headers;
}

function addJsonContentHeader(headers: Record<string, string>) {
  headers["Content-Type"] = "application/json";
  return headers;
}
